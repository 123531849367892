export const SET_PICK_UP_STORE = 'SET_PICK_UP_STORE';
export const CLEAR_PICK_UP_STORE = 'CLEAR_PICK_UP_STORE';

/** @namespace Bodypwa/Store/StoreInPickUp/Action/setPickUpStore */
export const setPickUpStore = (store) => ({
    type: SET_PICK_UP_STORE,
    store
});

/** @namespace Bodypwa/Store/StoreInPickUp/Action/clearPickUpStore */
export const clearPickUpStore = () => ({
    type: CLEAR_PICK_UP_STORE
});
