export const UPDATE_CONFIG = 'UPDATE_CONFIG';

/** @namespace Bodypwa/Store/Config/Action/updateConfig */
export const updateConfig = (config) => ({
    type: UPDATE_CONFIG,
    config
});

export const UPDATE_CONFIG_DEVICE = 'UPDATE_CONFIG_DEVICE';

/** @namespace Bodypwa/Store/Config/Action/updateConfigDevice */
export const updateConfigDevice = (device) => ({
    type: UPDATE_CONFIG_DEVICE,
    device
});

export const UPDATE_LAYOUT_UPDATES = 'UPDATE_LAYOUT_UPDATES';

/** @namespace Bodypwa/Store/Config/Action/updateLayoutUpdateConfig */
export const updateLayoutUpdateConfig = (layoutUpdates) => ({
    type: UPDATE_LAYOUT_UPDATES,
    layoutUpdates
});
