import { HIDE_ACTIVE_OVERLAY, HIDE_ACTIVE_POPUP } from 'Store/Overlay/Overlay.action';

import { SHOW_POPUP } from './Popup.action';

/** @namespace Bodypwa/Store/Popup/Reducer/getInitialState */
export const getInitialState = () => ({
    popupPayload: {},
    popupcContentRef: {},
    shouldPopupClose: false
});

/** @namespace Bodypwa/Store/Popup/Reducer/PopupReducer */
export const PopupReducer = (
    state = getInitialState(),
    action
) => {
    const { payload, type } = action;

    switch (type) {
    case SHOW_POPUP:
        return { ...state, popupPayload: payload };
    case HIDE_ACTIVE_OVERLAY:
        document.documentElement.style.top = 0;
        return { ...state, popupPayload: {} };
    case HIDE_ACTIVE_POPUP:
        return { ...state, shouldPopupClose: payload };
    default:
        return state;
    }
};

export default PopupReducer;
