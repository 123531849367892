import {
    RESET_CMS_BLOCKS,
    SET_CMS_BLOCK,
    SET_CMS_BLOCKS
} from './Cms.action';

export const initialState = {
    blocks: {}
};

/** @namespace Bodypwa/Store/Cms/Reducer/CmsReducer */
export const CmsReducer = (state = initialState, action) => {
    const { block: { content, identifier } = {}, items } = action;
    switch (action.type) {
        case SET_CMS_BLOCKS:
            const blocks = items.reduce((acc, { content, identifier }) => {
                acc[identifier] = { content, identifier };
                return acc;
            }, {});

        return {
            ...state,
            blocks: {
                ...state.blocks,
                ...blocks
            }
        };
    case SET_CMS_BLOCK:
        return {
            ...state,
            blocks: {
                ...state.blocks,
                [identifier]: content
            }
        };
    case RESET_CMS_BLOCKS:
        return {
            ...state,
            blocks: {}
        };
    default:
        return state;
    }
};

export default CmsReducer;
