// Util/SentryLoader/SentryLoader.js
import { CURRENT_VERSION } from 'Component/NewVersionPopup/NewVersionPopup.config';
import componentLoader from 'Util/componentLoader';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
let SentryInstance = null;

/** @namespace Bodypwa/Util/SentryLoader/loadSentry */
export function loadSentry() {
    return componentLoader(() => import(
        /* webpackMode: "lazy", webpackChunkName: "sentry" */
        '@sentry/browser'
    ), 2).then(
        /** @namespace Bodypwa/Util/SentryLoader/loadSentry/then/catch/componentLoader/then */
        (Sentry) => {
            Sentry.init({
                dsn: 'https://b89e256ab6a34f8aa0c479260effc07e@app.glitchtip.com/6147',
                release: `BodPwa@${CURRENT_VERSION}`,
                autoSessionTracking: false,
                allowUrls: [/https?:\/\/((cdn|www|preprod)\.)?bodymod\.[a-z]+/]
            });

            SentryInstance = Sentry;
            return Sentry;
        }
    ).catch(
        /** @namespace Bodypwa/Util/SentryLoader/loadSentry/then/catch */
        (error) => {
            console.error('Failed to load Sentry:', error);
            return null;
        }
    );
}

/** @namespace Bodypwa/Util/SentryLoader/initSentry */
export function initSentry() {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open('GET', `${document.location.href}?_=${new Date().getTime()}`, true);

        req.onload = () => {
            const headers = req.getAllResponseHeaders().split('\n');
            const sentryHeader = headers.find((header) => header.includes('x-enable-sentry'));

            if (sentryHeader) {
                const enableSentryValue = sentryHeader
                    .replace('x-enable-sentry:', '')
                    .trim()
                    .toLowerCase();

                const enableSentry = enableSentryValue === 'true';
                if (enableSentry) {
                    loadSentry();
                } else {
                    console.log('Sentry is disabled');
                }
            } else {
                console.log('No Sentry configuration header found');
            }

            resolve();
        };

        req.onerror = (err) => reject(err);
        req.send(null);
    });
}
export default initSentry;

/** @namespace Bodypwa/Util/SentryLoader/getSentryInstance */
export function getSentryInstance() {
    return SentryInstance;
}
