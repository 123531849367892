export const UPDATE_GMT_CONFIG = 'UPDATE_GMT_CONFIG';
export const EVENT_EXECUTED = 'EVENT_EXECUTED';
export const EXECUTE_EVENT = 'EXECUTE_EVENT';
export const EVENT_HISTORY = 'EVENT_HISTORY';
export const CLEAN_EVENT_HISTORY = 'CLEAN_EVENT_HISTORY';
export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';

/** @namespace Bodypwa/Store/GoogleTagManager/Action/updateConfig */
export const updateConfig = (payload) => ({
    type: UPDATE_GMT_CONFIG,
    payload
});

/** @namespace Bodypwa/Store/GoogleTagManager/Action/setExecuted */
export const setExecuted = (event) => ({
    type: EVENT_EXECUTED,
    event
});

/** @namespace Bodypwa/Store/GoogleTagManager/Action/event */
export const event = (event = '', custom = {}) => ({
    type: EXECUTE_EVENT,
    event,
    custom
});

/** @namespace Bodypwa/Store/GoogleTagManager/Action/pushEventHistory */
export const pushEventHistory = (event = '', fired = false) => ({
    type: EVENT_HISTORY,
    event,
    fired
});

/** @namespace Bodypwa/Store/GoogleTagManager/Action/cleanEventHistory */
export const cleanEventHistory = () => ({
    type: CLEAN_EVENT_HISTORY
});

/** @namespace Bodypwa/Store/GoogleTagManager/Action/setPageType */
export const setPageType = (pageType) => ({
    type: SET_PAGE_TYPE,
    pageType
});
