// import the original getStaticReducers function
// and rename it to baseGetStaticReducers
import { SideMenuReducer } from 'store/SideMenu/SideMenu.reducer';

import BlogReducer from 'Store/Blog/Blog.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import CmsReducer from 'Store/Cms/Cms.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import { CookiesReducer } from 'Store/Cookies/Cookies.reducer';
import { GoogleTagManagerReducer } from 'Store/GoogleTagManager/GoogleTagManager.reducer';
import MenuReducer from 'Store/Menu/Menu.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import { MobileSearchBarReducer } from 'Store/MobileSearchBar/MobileSearchBar.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import { PacketaReducer } from 'Store/Packeta/Packeta.reducer';
import { PaymentMethodsReducer } from 'Store/PaymentMethods/PaymentMethods.reducer';
import { PayPalPaymentReducer } from 'Store/PayPalPayment/PayPalPayment.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import { PostNordReducer } from 'Store/PostNord/PostNord.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import StoreInPickUpReducer from 'Store/StoreInPickUp/StoreInPickUp.reducer';
import StoresReducer from 'Store/Stores/Stores.reducer';
import UiReducer from 'Store/Ui/Ui.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';
// define getStaticReducers.
// this is a function that returns an object of all the reducers in the app.
// just like in the base theme...
/** @namespace Bodypwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    WishlistReducer,
    NoMatchReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    CheckoutReducer,
    StoreInPickUpReducer,
    MenuReducer,
    BlogReducer,
    StoresReducer,
    UiReducer,
    CmsReducer,
    CookiesReducer,
    SideMenuReducer,
    MobileSearchBarReducer,
    PayPalPaymentReducer,
    PaymentMethodsReducer,
    PacketaReducer,
    PostNordReducer,
    GoogleTagManagerReducer
});

// nothing new here, just copying the function from the base theme
// (this is necessary so that it uses our own `getStaticReducers` function
export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
