export const SET_CMS_BLOCKS = 'SET_CMS_BLOCKS';
/** @namespace Bodypwa/Store/Cms/Action/setCmsBlocks */
export const setCmsBlocks = (items) => ({
    type: SET_CMS_BLOCKS,
    items
});

export const SET_CMS_BLOCK = 'SET_CMS_BLOCK';
/** @namespace Bodypwa/Store/Cms/Action/setCmsBlock */
export const setCmsBlock = (block) => ({
    type: SET_CMS_BLOCK,
    block
});

export const RESET_CMS_BLOCKS = 'RESET_CMS_BLOCKS';
/** @namespace Bodypwa/Store/Cms/Action/resetCmsBlocks */
export const resetCmsBlocks = () => ({
    type: RESET_CMS_BLOCKS
});
