import { SET_PAYMENT_METHODS } from './PaymentMethods.action';

/** @namespace Bodypwa/Store/PaymentMethods/Reducer/getInitialState */
export const getInitialState = () => ({
    methods: []
});

/** @namespace Bodypwa/Store/PaymentMethods/Reducer/PaymentMethodsReducer */
export const PaymentMethodsReducer = (
    state = getInitialState(),
    action
) => {
    const { methods: stateMethods } = state;
    const { type, methods } = action;

    switch (type) {
    case SET_PAYMENT_METHODS:
        return {
            methods: [
                ...stateMethods,
                ...methods
            ]
        };
    default:
        return state;
    }
};

export default PaymentMethodsReducer;
