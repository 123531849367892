/* eslint-disable max-lines */
import {
    EVENT_GTM_USER_LOGIN, EVENT_GTM_USER_REGISTER
} from 'Component/GoogleTagManager/GoogleTagManager.events';
import { CHECKOUT, MY_ACCOUNT } from 'Component/Header/Header.config';
import MyAccountQuery from 'Query/MyAccount.query';
import OrderQuery from 'Query/Order.query';
import { event } from 'Store/GoogleTagManager/GoogleTagManager.action';
import {
    updateCustomerDetails,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    deleteAuthorizationToken,
    isSignedIn,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import {
    executePost, fetchMutation, fetchQuery, getErrorMessage
} from 'Util/Request';

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);

export const WishlistDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
), 2);

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;

/**
 * My account actions
 * @class MyAccount
 * @namespace Bodypwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher {
    forceLogoutRedirectPages = [
        CHECKOUT,
        MY_ACCOUNT
    ];

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};

        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/dispatch/catch */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }

    async logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();
        } else {
            const mutation = MyAccountQuery.getRevokeAccountToken();
            try {
                await fetchMutation(mutation);
            } catch (error) {
                dispatch(showNotification('error', getErrorMessage(error)));
            }
            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            async ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                await dispatcher.createGuestEmptyCartCustom(dispatch);
                dispatch(updateCustomerDetails({}));
            }
        );

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );
    }

    /**
     * Forgot password action
     * @param {{email: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    forgotPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getForgotPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/forgotPassword/fetchMutation/then/dispatch */
            () => dispatch(updateCustomerPasswordForgotStatus()),
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/forgotPassword/fetchMutation/then/dispatch/catch */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }

    /**
     * Reset password action
     * @param {{token: String, password: String, password_confirmation: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch */
            ({ s_resetPassword: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch/catch */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation, null, true).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return 2;
                }
                dispatch(event(EVENT_GTM_USER_REGISTER));
                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    /**
     * Confirm account action
     * @param {{key: String, email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    confirmAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getConfirmAccountMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/confirmAccount/fetchMutation/then/dispatch */
            () => dispatch(showNotification('success', __('Your account is confirmed!'))),
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/confirmAccount/fetchMutation/then/dispatch/catch */
            (error) => dispatch(
                showNotification(
                    'error',
                    getErrorMessage(error, __('Something went wrong! Please, try again!'))
                )
            )
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(event(EVENT_GTM_USER_LOGIN));
        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    handleForceRedirectToLoginPage() {
        const { location: { pathname = '' } = {} } = history;
        const doRedirect = this.forceLogoutRedirectPages.reduce((result, urlPart) => {
            if (pathname.includes(urlPart)) {
                return true;
            }

            return result;
        }, false);

        if (doRedirect) {
            history.push({ pathname: '/account/login' });
        }
    }

    validateSession(dispatch) {
        const query = OrderQuery.getOrderListQuery();
        fetchQuery(query).then(
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/validateSession/fetchQuery/then */
            () => {},
            /** @namespace Bodypwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/validateSession/fetchQuery/then/catch */
            () => {
                this.logout(false, dispatch);
            }
        );

        return null;
    }

    handleCustomerDataOnInit(dispatch) {
        if (isSignedIn()) {
            this.validateSession(dispatch);
            return;
        }

        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
        );

        if (isSignedIn()) {
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
            );
        }
    }
}

export default new MyAccountDispatcher();
