import {
    POSTNORD_SET_ADDRESS_POINT, POSTNORD_SET_POINT,
    POSTNORD_SET_SLECTED_POINT, RESET_POSTNORD
} from './PostNord.action';

/** @namespace Bodypwa/Store/PostNord/Reducer/getInitialState */
export const getInitialState = () => ({
    point: {},
    points: [],
    selectedPoint: ''
});

/** @namespace Bodypwa/Store/PostNord/Reducer/PostNordReducer */
export const PostNordReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case POSTNORD_SET_POINT:
        const { point } = action;

        return {
            ...state,
            point
        };
    case POSTNORD_SET_ADDRESS_POINT:
        const { points } = action;
        const formatedPoints = [];
        points.forEach((point) => {
            formatedPoints.push(JSON.parse(point));
        });

        return {
            ...state,
            points: formatedPoints
        };
    case POSTNORD_SET_SLECTED_POINT:
        const { selectedPoint } = action;
        return {
            ...state,
            selectedPoint
        };
    case RESET_POSTNORD:
        return getInitialState();
    default:
        return state;
    }
};

export default PostNordReducer;
