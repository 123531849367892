export const SHOW_OFFLINE_NOTICE = 'SHOW_OFFLINE_NOTICE';
export const SET_BIG_OFFLINE_NOTICE = 'SET_BIG_OFFLINE_NOTICE';

/**
 * Show offline notice.
 * @param  {boolean} msgType
 * @return {void}
 * @namespace Bodypwa/Store/Offline/Action/showOfflineNotice */
export const showOfflineNotice = (isOffline) => ({
    type: SHOW_OFFLINE_NOTICE,
    isOffline
});

/**
 * Set offline notice size to big.
 * @param  {boolean} isBig
 * @return {void}
 * @namespace Bodypwa/Store/Offline/Action/setBigOfflineNotice */
export const setBigOfflineNotice = (isBig) => ({
    type: SET_BIG_OFFLINE_NOTICE,
    isBig
});
