import {
    SET_BLOG_DATA,
    SET_BLOG_LOADING_STATUS
} from 'Store/Blog/Blog.action';

export const initialState = {
    settings: {},
    posts: {},
    category: {},
    categoryPosts: {},
    editorsPicksPosts: {},
    currentCategoryPage: 1,
    totalCategoryPosts: 0,
    totalCategoryPages: 0,
    currentEditorsPicksPage: 1,
    totalEditorsPicksPosts: 0,
    totalEditorsPicksPages: 0,
    post: {},
    categories: [],
    tags: [],
    authors: [],
    comments: [],
    relatedPosts: [],
    relatedProducts: [],
    isLoading: false,
    currentArgs: {},
    meta: {
        meta_title: '',
        meta_tags: '',
        meta_description: '',
        meta_robots: ''
    },
    metaPost: {
        meta_title: '',
        meta_tags: '',
        meta_description: '',
        meta_robots: ''
    },
    metaCategory: {
        meta_title: '',
        meta_tags: '',
        meta_description: '',
        meta_robots: ''
    }
};
/** @namespace Bodypwa/Store/Blog/Reducer/setBlogData */
export const setBlogData = (state, queryType, data, args) => {
    const {
        meta_title, meta_tags, meta_description, meta_robots
    } = data;

    // const { currentArgs } = state;
    switch (queryType) {
    case 'categoryPosts':
        const {
            items: categoryPosts,
            all_post_size: totalCategoryPosts,
            page_info: { total_pages: totalCategoryPages } = {}
        } = data;

        return {
            ...state,
            categoryPosts: {
                ...state.categoryPosts,
                [args.page]: categoryPosts
            },
            currentCategoryPage: args.page,
            totalCategoryPosts,
            totalCategoryPages,
            currentArgs: args,
            isLoading: false
        };

    case 'editorsPicksPosts': // Alias for `amBlogPosts`
        const {
            items: editorsPicksPosts,
            all_post_size: totalEditorsPicksPosts,
            page_info: { total_pages: totalEditorsPicksPages } = {}
        } = data;

        return {
            ...state,
            editorsPicksPosts: {
                ...state.editorsPicksPosts,
                [args.page]: editorsPicksPosts
            },
            currentEditorsPicksPage: args.page,
            totalEditorsPicksPosts,
            totalEditorsPicksPages,
            currentArgs: args,
            isLoading: false
        };
    case 'amBlogCategory':
        return {
            ...state,
            category: data,
            metaCategory: {
                meta_title, meta_tags, meta_description, meta_robots
            },
            isLoading: false
        };
    case 'amBlogCategories':
        return {
            ...state,
            categories: data.items,
            isLoading: false
        };
    case 'amBlogTag':
        return {
            ...state,
            tag: data,
            meta: {
                meta_title, meta_tags, meta_description, meta_robots
            },
            isLoading: false
        };
    case 'amBlogAllTags':
        return {
            ...state,
            tags: data.items || [],
            isLoading: false
        };
    case 'amBlogPost':
        return {
            ...state,
            post: data,
            metaPost: {
                meta_title, meta_tags, meta_description, meta_robots
            },
            isLoading: false
        };
    case 'amBlogPostsByIds':
        return {
            ...state,
            relatedPosts: data.items,
            isLoading: false
        };
    case 'amBlogAuthors':
        return {
            ...state,
            authors: data.amBlogAuthors.items,
            isLoading: false
        };
    case 'amBlogComments':
        return {
            ...state,
            comments: data.amBlogComments.items,
            isLoading: false
        };
    case 'amBlogPostRelatedProducts':
        return {
            ...state,
            relatedProducts: data.amBlogPostRelatedProducts.items,
            isLoading: false
        };
    default:
        return state;
    }
};

/** @namespace Bodypwa/Store/Blog/Reducer/BlogReducer */
export const BlogReducer = (state = initialState, action) => {
    const {
        type, data, args, isLoading
    } = action;

    switch (type) {
    case SET_BLOG_DATA:
        // Process each item in args and update state based on alias or queryType
        let newState = { ...state };

        args.forEach((arg) => {
            const { args: argParams, alias, queryType } = arg;
            const typeKey = alias || queryType; // Use alias if present, otherwise queryType

            if (data[typeKey]) {
                // Update state using setBlogData for each part of the response
                newState = setBlogData(newState, typeKey, data[typeKey], argParams);
            }
        });

        return newState;

    case SET_BLOG_LOADING_STATUS:
        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default BlogReducer;
