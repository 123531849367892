import {
    SET_BIG_OFFLINE_NOTICE,
    SHOW_OFFLINE_NOTICE
} from './Offline.action';

/** @namespace Bodypwa/Store/Offline/Reducer/getInitialState */
export const getInitialState = () => ({
    isOffline: true,
    isBig: false
});

/** @namespace Bodypwa/Store/Offline/Reducer/OfflineReducer */
export const OfflineReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SHOW_OFFLINE_NOTICE:
        const { isOffline } = action;

        return {
            ...state,
            isOffline
        };
    case SET_BIG_OFFLINE_NOTICE:
        const { isBig } = action;

        return {
            ...state,
            isBig
        };
    default:
        return state;
    }
};

export default OfflineReducer;
