import { Field } from 'Util/Query';

/** @namespace Bodypwa/Query/I18n/Query */
export class I18nQuery {
    getQuery() {
        return new Field('storeConfig')
            .addFieldList(this._getStoreConfigFields());
    }

    _getStoreConfigFields() {
        return [
            'locale'
        ];
    }
}
export default new I18nQuery();
