/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CURRENT_CATEGORY, UPDATE_CURRENT_CATEGORY_CMS, UPDATE_PREV_CATEGORY } from './Category.action';

/** @namespace Bodypwa/Store/Category/Reducer/getInitialState */
export const getInitialState = () => ({
    category: {},
    prevCategory: {}
});

/** @namespace Bodypwa/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer = (
    state = getInitialState(),
    { type, category }
) => {
    const {
        category: prevCat, originalCategory = {},
        category: {
            requestOriginal: original = false,
            id: originalID, seo: prevSeo = false
        } = {}
    } = state;
    const { seo = false, requestOriginal, id } = category || {};
    if (original === false && requestOriginal === undefined
        && (category !== undefined || id === originalID)
        && prevSeo === true) {
        return {
            ...state,
            category: { ...category, ...prevCat }
        };
    }

    if (requestOriginal) {
        switch (type) {
        case UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: originalCategory
            };
        default:
            return state;
        }
    }

    if (seo) {
        switch (type) {
        case UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: { ...prevCat, ...category }
            };

        default:
            return state;
        }
    }

    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return {
            ...state,
            category,
            originalCategory: { category }
        };
    case UPDATE_CURRENT_CATEGORY_CMS:
        return {
            ...state,
            category: { ...state.category, ...category }
        };
    case UPDATE_PREV_CATEGORY:
        return {
            ...state,
            prevCategory: category
        };
    default:
        return state;
    }
};

export default CategoryReducer;
