export const UPDATE_PRODUCT_LIST_INFO = 'UPDATE_PRODUCT_LIST_INFO';
export const UPDATE_INFO_LOAD_STATUS = 'UPDATE_INFO_LOAD_STATUS';
export const UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER';
export const REMOVE_ACTIVE_FILTER = 'REMOVE_ACTIVE_FILTER';

/** @namespace Bodypwa/Store/ProductListInfo/Action/updateProductListInfo */
export const updateProductListInfo = (products, filter) => ({
    type: UPDATE_PRODUCT_LIST_INFO,
    products,
    selectedFilter: filter
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Bodypwa/Store/ProductListInfo/Action/updateInfoLoadStatus */
export const updateInfoLoadStatus = (status) => ({
    type: UPDATE_INFO_LOAD_STATUS,
    isLoading: status
});
/** @namespace Bodypwa/Store/ProductListInfo/Action/updateActiveFilter */
export const updateActiveFilter = (activeFilter) => ({
    type: UPDATE_ACTIVE_FILTER,
    activeFilter
});

/** @namespace Bodypwa/Store/ProductListInfo/Action/removeActiveFilter */
export const removeActiveFilter = (attribute_code, value_string) => ({
    type: REMOVE_ACTIVE_FILTER,
    attribute_code,
    value_string
});
