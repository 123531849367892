import { EVENT_GTM_DYNAMIC_RETARGETING } from '../../component/GoogleTagManager/GoogleTagManager.events';
import {
    CLEAN_EVENT_HISTORY,
    EVENT_EXECUTED, EVENT_HISTORY, EXECUTE_EVENT,
    SET_PAGE_TYPE,
    UPDATE_GMT_CONFIG
} from './GoogleTagManager.action';
/** @namespace Bodypwa/Store/GoogleTagManager/Reducer/getInitialState */
export const getInitialState = () => ({
    isExecuted: true,
    config: {
        enabled: false,
        events: {}
    },
    eventList: [],
    pageType: 'other'
});

/** @namespace Bodypwa/Store/GoogleTagManager/Reducer/GoogleTagManagerReducer */
export const GoogleTagManagerReducer = (state = getInitialState(), action) => {
    const { eventList } = state;
    const {
        event, fired, data, custom
    } = action;

    switch (action.type) {
    case UPDATE_GMT_CONFIG:
        const { payload: { gtm, gtm: { events } = {} } = {} } = action;

        return {
            ...state,
            config: {
                ...gtm,
                events: {
                    ...events,
                    [EVENT_GTM_DYNAMIC_RETARGETING]: true
                }

            }
        };

    case EVENT_EXECUTED:
        return {
            ...state,
            isExecuted: true
        };
    case EXECUTE_EVENT:
        if (!eventList[event] || !eventList[event].fired) {
            eventList[event] = {
                fired: false,
                data: custom
            };
        }

        return {
            ...state,
            event: action.event,
            custom: action.custom,
            isExecuted: false,
            eventList
        };
    case EVENT_HISTORY:
        eventList[event] = {
            data,
            fired
        };

        return {
            ...state,
            eventList
        };
    case CLEAN_EVENT_HISTORY:
        return {
            ...state,
            event: '',
            eventList: []
        };
    case SET_PAGE_TYPE:
        return {
            ...state,
            pageType: action.pageType
        };
    default:
        return state;
    }
};

export default GoogleTagManagerReducer;
