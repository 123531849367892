import { HIDE_ACTIVE_POPUP } from 'Store/Overlay/Overlay.action';

export const SHOW_POPUP = 'SHOW_POPUP';

/** @namespace Bodypwa/Store/Popup/Action/closePopup */
export const closePopup = (overlayKey, payload) => ({
    type: HIDE_ACTIVE_POPUP,
    overlayKey,
    payload: { [overlayKey]: payload }
});

/** @namespace Bodypwa/Store/Popup/Action/showPopup */
export const showPopup = (overlayKey, payload) => ({
    type: SHOW_POPUP,
    overlayKey,
    payload: { [overlayKey]: payload }
});
