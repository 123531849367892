import {
    UPDATE_NOMATCH
} from './NoMatch.action';

/** @namespace Bodypwa/Store/NoMatch/Reducer/getInitialState */
export const getInitialState = () => ({
    noMatch: false
});

/** @namespace Bodypwa/Store/NoMatch/Reducer/NoMatchReducer */
export const NoMatchReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_NOMATCH:
        const { noMatch } = action;

        return { noMatch };

    default:
        return state;
    }
};

export default NoMatchReducer;
