import { UPDATE_COUNT_DOWN_SALE_CONFIG } from 'Store/SaleCountdown/SaleCountdown.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_CONFIG, UPDATE_CONFIG_DEVICE, UPDATE_LAYOUT_UPDATES } from './Config.action';

export const MAX_WIDTH = 150;
export const MAX_HEIGHT = 40;
export const DEFAULT_CATGORY_URL_SUFFIX = '.html';
/** @namespace Bodypwa/Store/Config/Reducer/filterStoreConfig */
export const filterStoreConfig = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => (value !== null ? { ...acc, [key]: value } : acc),
    {}
);

export const {
    countries, reviewRatings, storeConfig, currencyData, cartDisplayConfig, layoutUpdates
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    layoutUpdates: [],
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false
    }
};

/** @namespace Bodypwa/Store/Config/Reducer/getIndexedRatings */
export const getIndexedRatings = (reviewRatings) => ((reviewRatings) ? reviewRatings.items || [] : []);

/** @namespace Bodypwa/Store/Config/Reducer/getCurrencyData */
export const getCurrencyData = (base, state) => (base || state.currencyData || {});

/** @namespace Bodypwa/Store/Config/Reducer/getCountryData */
export const getCountryData = (base, state) => (base || state.countries || {});

/** @namespace Bodypwa/Store/Config/Reducer/getCheckoutAgreementData */
export const getCheckoutAgreementData = (base, state) => (base || state.checkoutAgreements || {});

/** @namespace Bodypwa/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...filterStoreConfig(storeConfig),
    countries,
    reviewRatings,
    checkoutAgreements: [],
    currencyData,
    isLoading: true,
    cartDisplayConfig,
    priceTaxDisplay: {},
    category_url_suffix: DEFAULT_CATGORY_URL_SUFFIX,
    device: {
        isMobile: true,
        android: true,
        ios: false,
        blackberry: false,
        opera: false,
        windows: false,
        standaloneMode: window.matchMedia('(display-mode: standalone)').matches
    },
    amBlogSetting: {},
    layoutUpdates: []
});

/** @namespace Bodypwa/Store/Config/Reducer/getAmBlogSetting */
export const getAmBlogSetting = (base, state) => {
    const {
        search_engine_meta_title: meta_title,
        search_engine_meta_description: meta_description,
        search_engine_meta_keywords: meta_tags,
        search_engine_meta_robots: meta_robots
    } = base;
    const meta = {
        meta_title, meta_tags, meta_description, meta_robots
    };

    return { ...base, meta } || state.amBlogSetting || {};
};

/** @namespace Bodypwa/Store/Config/Reducer/getLUFromAction */
export const getLUFromAction = (base, state) => (base || state.layoutUpdates || {});

/** @namespace Bodypwa/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        layoutUpdates: {
            widgets: {
                items: layoutUpdatesContent = []
            } = {}
        } = {},
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {},
            layoutUpdates,
            amBlogSetting
        } = {},
        device
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;
        window.secure_base_media_url = secure_base_media_url;

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currencyData: getCurrencyData(currencyData, state),
            layoutUpdates: getLUFromAction(layoutUpdates, state),
            amBlogSetting: getAmBlogSetting(amBlogSetting, state),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };
    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };
    case UPDATE_COUNT_DOWN_SALE_CONFIG:
        const { payload } = action;
        return {
            ...state,
            ...payload
        };
    case UPDATE_LAYOUT_UPDATES:
        const { layoutUpdates: originalLayoutUpdates } = state;
        const blocksById = layoutUpdatesContent.reduce((map, block) => {
            // eslint-disable-next-line no-param-reassign
            map[block.id] = block;
            return map;
        }, {});

        const newLayoutUpdates = originalLayoutUpdates.map((page) => {
            const matchingBlock = blocksById[page.instance_id];
            return matchingBlock
                ? { ...page, ...matchingBlock }
                : page;
        });

        return {
            ...state,
            layoutUpdates: newLayoutUpdates
        };
    default:
        return state;
    }
};
export default ConfigReducer;
