export const PACKETA_SET_POINT = 'PACKETA_SET_POINT';
export const PACHETA_SET_WIDGET = 'PACHETA_SET_WIDGET';
export const RESET_PACKETA = 'RESET_PACKETA';
/** @namespace Bodypwa/Store/Packeta/Action/setPacketaPoint */
export const setPacketaPoint = (point) => ({
    type: PACKETA_SET_POINT,
    point
});

/** @namespace Bodypwa/Store/Packeta/Action/setPacketaWidget */
export const setPacketaWidget = (isSet) => ({
    type: PACHETA_SET_WIDGET,
    isSet
});

/** @namespace Bodypwa/Store/Packeta/Action/resetPacketa */
export const resetPacketa = () => ({
    type: RESET_PACKETA
});
