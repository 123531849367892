export const APPEND_PAGE = 'APPEND_PAGE';
export const UPDATE_PRODUCT_LIST_ITEMS = 'UPDATE_PRODUCT_LIST_ITEMS';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';
export const UPDATE_PAGE_LOAD_STATUS = 'UPDATE_PAGE_LOAD_STATUS';

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Bodypwa/Store/ProductList/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_LOAD_STATUS,
    isLoading: status
});

/** @namespace Bodypwa/Store/ProductList/Action/updatePageLoadingStatus */
export const updatePageLoadingStatus = () => ({
    type: UPDATE_PAGE_LOAD_STATUS
});

export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

export const CLEAN_PRODUCT_LIST = 'CLEAN_PRODUCT_LIST';

export const REMOVE_PRODUCT_LIST_ITEMS = 'REMOVE_PRODUCT_LIST_ITEMS';

export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';

export const UPDATE_CATEGORY_PAGE = 'UPDATE_CATEGORY_PAGE';

export const UPDATE_PRODUCT_LIST_PRICE = 'UPDATE_PRODUCT_LIST_PRICE';

/** @namespace Bodypwa/Store/ProductList/Action/cleanProductList */
export const cleanProductList = (
) => ({
    type: CLEAN_PRODUCT_LIST
});

/** @namespace Bodypwa/Store/ProductList/Action/removeProductListItems */
export const removeProductListItems = (
    currentPage
) => ({
    type: REMOVE_PRODUCT_LIST_ITEMS,
    currentPage
});

/** @namespace Bodypwa/Store/ProductList/Action/updateProducts */
export const updateProducts = (
    products, categoryId, currentPage
) => ({
    type: UPDATE_PRODUCTS,
    products,
    categoryId,
    currentPage
});

/** @namespace Bodypwa/Store/ProductList/Action/updateProductList */
export const updateProductList = (productListAddtionalData, id) => ({
    type: UPDATE_PRODUCT_LIST,
    productListAddtionalData,
    id
});

/**
 * Append page to the list.
 * @param {Array<Object>} items List of products returned from fetch
 * @param {Number} minPrice Minimal products price returned from fetch
 * @param {Number} maxPrice Maximal products price returned from fetch
 * @param {Number} currentPage Number of requested page
 * @namespace Bodypwa/Store/ProductList/Action/appendPage */
export const appendPage = (items, currentPage, id) => ({
    type: APPEND_PAGE,
    items,
    currentPage,
    id
});

/**
 * Update product list with new list (rewrite if already exists).
 * @param {Array<Object>} items List of products returned from fetch
 * @param {Number} currentPage Number of requested page
 * @param {Number} total_count Number of requested page
 * @return {void}
 * @namespace Bodypwa/Store/ProductList/Action/updateProductListItems */
export const updateProductListItems = (
    items,
    currentPage,
    total_count,
    total_pages,
    args,
    id
) => ({
    type: UPDATE_PRODUCT_LIST_ITEMS,
    items,
    currentPage,
    total_pages,
    total_count,
    args,
    id
});

/** @namespace Bodypwa/Store/ProductList/Action/updateCategoryPage */
export const updateCategoryPage = (categoryPage) => ({
    type: UPDATE_CATEGORY_PAGE,
    categoryPage
});
