/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    CLOSE_SIDE_MENU, GET_PROMO_IMAGE,
    HIDE_MAIN_LEVEL, OPEN_SIDE_MENU, SET_PROMO_IMAGE,
    SHOW_MAIN_LEVEL
} from './SideMenu.action';

/** @namespace Bodypwa/Store/SideMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    isSideMenuOpen: false,
    isSubMenuActive: false,
    promoImage: '',
    menuTitle: ''
});

/** @namespace Bodypwa/Store/SideMenu/Reducer/SideMenuReducer */
export const SideMenuReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case OPEN_SIDE_MENU:
        return {
            ...state,
            isSideMenuOpen: true
        };

    case CLOSE_SIDE_MENU:
        return {
            ...state,
            isSideMenuOpen: false
        };
    case HIDE_MAIN_LEVEL:
        return {
            ...state,
            isSubMenuActive: true
        };
    case SHOW_MAIN_LEVEL:
        return {
            ...state,
            isSubMenuActive: false
        };
    case SET_PROMO_IMAGE:
        return {
            ...state,
            promoImage: action.promoImage
        };
    case GET_PROMO_IMAGE:
        return state.promoImage;
    default:
        return state;
    }
};

export default SideMenuReducer;
