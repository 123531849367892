export const CHANGE_NAVIGATION_STATE = 'CHANGE_NAVIGATION_STATE';
export const GOTO_PREVIOUS_NAVIGATION_STATE = 'GOTO_PREVIOUS_NAVIGATION_STATE';

/** @namespace Bodypwa/Store/Navigation/Action/changeNavigationState */
export const changeNavigationState = (navigationType, navigationState) => ({
    type: CHANGE_NAVIGATION_STATE,
    navigationType,
    navigationState
});

/** @namespace Bodypwa/Store/Navigation/Action/goToPreviousNavigationState */
export const goToPreviousNavigationState = (navigationType) => ({
    type: GOTO_PREVIOUS_NAVIGATION_STATE,
    navigationType
});
