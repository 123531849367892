export const BR_AFTER_PAGE_HEADER_TOP = 'top.container'; // Inside demo notice
export const BR_PAGE_HEADER_CONTAINER = 'header.container'; // Inside header
export const BR_PAGE_HEADER = 'header-wrapper'; // Inside header navigation
export const BR_PAGE_HEADER_PANEL = 'header.panel'; // Inside header top menu
export const BR_AFTER_PAGE_HEADER = 'page.top'; // After header

export const BR_BEFORE_PAGE_FOOTER_CONTAINER = 'page.bottom.container'; // Before footer
export const BR_PAGE_FOOTER_CONTAINER = 'footer-container'; // Inside footer
export const BR_BEFORE_PAGE_FOOTER = 'page.bottom'; // Inside footer content
export const BR_PAGE_FOOTER = 'footer'; // Inside footer content
export const BR_CMS_FOOTER_LINKS = 'cms_footer_links_container'; // Inside footer columns

export const BR_MAIN_CONTENT_CONTAINER = 'main'; // main content container
export const BR_MAIN_CONTENT_TOP = 'content.top'; // inside main content, on the top
export const BR_MAIN_CONTENT_AREA = 'content'; // inside main content, on the top, before content.top
export const BR_MAIN_CONTENT_BOTTOM = 'content.bottom'; // inside main content, on the bottom
export const BR_MAIN_CONTENT_ASIDE = 'content.aside'; // not support in ScandiPWA, on the bottom, after content.bottom

export const BR_PAGE_TOP = 'after.body.start'; // before router
export const BR_PAGE_BOTTOM = 'before.body.end'; // after router
export const BR_BEFORE_MAIN_COLUMNS = 'columns.top'; // before router, after after.body.start

export const BR_MINI_CART_PROMOTION_BLOCK = 'minicart.addons'; // after cart promo

export const BR_ALERT_URLS = 'alert.urls'; // product alerts
export const BR_PRODUCT_VIEW_AFTER_TITLE = 'pdp.after.title';
export const BR_PRODUCT_VIEW_AFTER_PRICE = 'pdp.after.price';
export const BR_PRODUCT_VIEW_EXTRA_HINT = 'product.info.extrahint'; // before brand on mobile, after price on desktop
export const BR_PRODUCT_EXTRA_INFO = 'product.info.simple.extra'; // before brand on mobile, after price on desktop
export const BR_PRODUCT_AUXILIARY_INFO = 'product.info.stock.sku'; // product sku & stock
export const BR_PRODUCT_INFO_AUXILIARY_CONTAINER = 'product.info.price'; // product price
export const BR_PRODUCT_SOCIAL_LINKS_CONTAINER = 'product.info.social'; // last in product actions
export const BR_PRODUCT_IMAGE = 'container_product_image_1'; // after product image form
export const BR_PRODUCT_CONTAINER_LOWER_PRIMARY_2 = 'container_product_lower_primary_2'; // udner productactions
export const BR_REVIEW_FORM_FIELDS_BEFORE = 'product.review.form.fields.before'; // before product reviews form
export const BR_CHECKOUT_SUCCES = 'order.success.additional.info'; // after order success info
// vvv Not supported
export const BR_SIDEBAR_ADDITIONAL = 'sidebar.additional';
export const BR_SIDEBAR_MAIN = 'sidebar.main';
