import {
    UPDATE_INFO_COOKIE_GROUP
} from './Cookies.action';

/** @namespace Bodypwa/Store/Cookies/Reducer/getInitialState */
export const getInitialState = () => ({
    cookieInfo: {}
});

/** @namespace Bodypwa/Store/Cookies/Reducer/CookiesReducer */
export const CookiesReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        cookieInfo
    } = action;

    switch (type) {
    case UPDATE_INFO_COOKIE_GROUP:

        return {
            ...state,
            cookieInfo
        };
    default:
        return state;
    }
};

export default CookiesReducer;
