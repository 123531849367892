// TODO update this import when renamed
import { PACHETA_SET_WIDGET, PACKETA_SET_POINT, RESET_PACKETA } from './Packeta.action';

/** @namespace Bodypwa/Store/Packeta/Reducer/getInitialState */
export const getInitialState = () => ({
    point: {}
});

/** @namespace Bodypwa/Store/Packeta/Reducer/PacketaReducer */
export const PacketaReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case PACKETA_SET_POINT:
        const { point } = action;
        return {
            ...state,
            point
        };
    case PACHETA_SET_WIDGET:
        const { isSet } = action;
        return {
            ...state,
            isWidget: isSet
        };
    case RESET_PACKETA:
        return getInitialState();
    default:
        return state;
    }
};

export default PacketaReducer;
