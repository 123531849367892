import { CLEAR_PICK_UP_STORE, SET_PICK_UP_STORE } from './StoreInPickUp.action';

/** @namespace Bodypwa/Store/StoreInPickUp/Reducer/getInitialState */
export const getInitialState = () => ({
    store: null
});

/** @namespace Bodypwa/Store/StoreInPickUp/Reducer/StoreInPickUpReducer */
export const StoreInPickUpReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_PICK_UP_STORE:
        const { store } = action;

        return {
            ...state,
            store
        };

    case CLEAR_PICK_UP_STORE:
        return {
            ...state,
            store: null
        };

    default:
        return state;
    }
};

export default StoreInPickUpReducer;
