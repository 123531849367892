export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const HIDE_ACTIVE_OVERLAY = 'HIDE_ACTIVE_OVERLAY';
export const HIDE_ACTIVE_POPUP = 'HIDE_ACTIVE_POPUP';

/** @namespace Bodypwa/Store/Overlay/Action/toggleOverlayByKey */
export const toggleOverlayByKey = (overlayKey) => ({
    type: TOGGLE_OVERLAY,
    overlayKey
});

/** @namespace Bodypwa/Store/Overlay/Action/hideActiveOverlay */
export const hideActiveOverlay = () => ({
    type: HIDE_ACTIVE_OVERLAY
});

/** @namespace Bodypwa/Store/Overlay/Action/hideActivePopup */
export const hideActivePopup = (shouldPopupClose = true) => ({
    type: HIDE_ACTIVE_POPUP,
    payload: shouldPopupClose
});
